import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './App.css';
import Tabs from "./components/Tabs";
import Button from 'react-bootstrap/Button';


function App() {
  return (
    <Container fluid>
      <Row >
        <Col>
          <div className="header">
            <div className="headertitulo">
              <div >
                <img
                  className='logoh'
                  src={require('./logos-transmisiones.png')}
                  alt='logo amcg' />

              </div>

              <div className="headertexto">
                <p className="nombretransmision">Nuevas Herramientas en Cirugía Endócrina: Evidencia en 2022</p>
                <p className="fechatransmision">27 de julio, 20:00 – 21:30 horas</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className='videoseccion'>
        <Col sm >
          <iframe src="https://player.vimeo.com/video/733804653?h=6718c06abe&title=0&byline=0&portrait=0" width="100%" height="450" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          <p className='link'>Si no visualiza la transmisión a la hora señalada de la Sesión Académica, actualice la página o de <a href="https://amcg.mx/">clic aquí</a></p>
        </Col>
        <Col sm >
          <Tabs >
            <div label="Chat">
              <div className='container'>
                <iframe src="https://vimeo.com/event/2311158/chat/" width="100%" height="100%" frameborder="0"></iframe>

              </div>
            </div>
            <div label="Programa">
              <p className='titulotabs'>Programa</p>
              <br />
              <ul className='listatabs'>
                <li className='lista'>
                  <p className='horatabs'> 20:00 – 20:10 hrs</p>
                  <p className='titulopresentacion'>Introducción</p>
                  <p className='nombredoctores'>Dr. Miguel F. Herrera Hernández, Presidente de la AMCG</p>
                  <p className='nombredoctores'>Dr. Mauricio Sierra Salazar, Coordinador General de Sesiones Académicas AMCG</p>
                  <p className='nombredoctores'>Dr. Luis Alfonso Martín del Campo González, Coordinador General de Sesiones Académicas AMCG</p>
                  <p className='nombredoctores'>Dr. Jorge Montalvo Hernández, Coordinador titular de la Sesión</p>
                  <p className='nombredoctores'>Dr. Rafael Humberto Pérez Soto, Coordinador adjunto de la Sesión</p>

                </li>
                <li className='lista'>
                  <p className='horatabs'>20:10 – 20:30 hrs
                  </p>
                  <p className='titulopresentacion'>Uso intraoperatorio de fluorescencia en cirugía endocrina: imagen por autofluorescencia, sonda y verde de indocianina</p>
                  <p className='nombredoctores'>Dr. Quan-Yang Duh</p>
                  <p className='nombredoctores'>UCSF, California</p>
                </li>
                <li className='lista'>
                  <p className='horatabs'>20:30 – 20:50 hrs</p>
                  <p className='titulopresentacion'>Neuromonitorización Intraoperatoria en cirugía tiroidea y paratiroidea, ¿Lujo o estándar?</p>
                  <p className='nombredoctores'>Dr. David Velázquez Fernández</p>
                  <p className='nombredoctores'>Instituto Nacional de Ciencias Médicas y Nutrición Salvador Zubirán, Ciudad de México</p>

                </li>
                <li className='lista'>
                  <p className='horatabs'>20:50 – 21:10 hrs</p>
                  <p className='titulopresentacion'>Ultrasonido y procedimientos invasivos no quirúrgicos para enfermedad tiroidea y paratiroidea</p>
                  <p className='nombredoctores'> Dr. Cord Sturgeon</p>
                  <p className='nombredoctores'> Northwestern Memorial Hospital, Chicago IL</p>
                </li>
                <li className='lista'>
                  <p className='horatabs'>21:10 – 21:30 hrs</p>
                  <p className='titulopresentacion'>Preguntas y comentarios finales</p>
                  <p className='nombredoctores'> Coordinadores</p>
                </li>               
              </ul>
            </div>
            <div label="¿Qué es la AMCG?">
              <img className='logoh2'
                src={require('./logo.png')}
                alt='logo amcg' />
              <p className='titulotabs'>¿Qué es la AMCG?</p>
              <p className='textotabs'>La Asociación Mexicana de Cirugía General, A. C.,
                es una organización integrada por cirujanos generales con título
                reconocido por las autoridades competentes. Dentro de sus finalidades,
                proclama la necesidad de pugnar por una mejor preparación científica,
                técnica, cultural, moral, legal y social de todos sus Asociados para
                optimizar las capacidades del profesionista, a fin de ejercer sus
                labores dentro de un marco ético, legal y del más alto nivel técnico
                que demanda esta especialidad. Como grupo social que realiza sus
                actividades para lograr la salud y bienestar del ser humano y la sociedad,
                se esforzará porque sus miembros actúen con la responsabilidad que nuestra
                especialidad exige.
                <br />
                <a href='https://amcg.org.mx/' target="_blank">Más información →</a>
              </p>
            </div>
          </Tabs>

        </Col>
      </Row>
      <Row className='constancia'>
        <Col>
        <p className='titulocons'>Solicite su constancia</p>
        <p className='textocons'>El formulario de registro de constacias se habilita el mismo día de la sesión Académica</p>
        <div className='col-centered'>
        <a href='https://constancias.amcg.mx/registros/' target="_blank" > <Button variant="warning">Ir a registro</Button>{' '}</a>

        </div>
 
      
        </Col>
      </Row>
    </Container>

  );
}

export default App;
